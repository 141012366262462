.editor {
  padding: 10px;
}

.id {
  margin-bottom: 10px;
}

.label {
  margin-bottom: 10px;
}
