.node {
    border: 2px solid #333;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 12px;
}

.node:hover {
    background-color: aqua;
    cursor: pointer;
}