.page {
  display: grid;
  grid-template-row: 70px 1fr;
}

.header {
  position: relative;
  z-index: 2;
}

.body {
  position: relative;
  z-index: 1;
}

.inner {
  height: calc(100vh - 70px);
}
